import { FieldListModel } from '../../../core/_ui/models/list/field-list.model';

export declare class GenericItems {
  [key: string]: any;
}

export class ArchiveFinderModel extends FieldListModel {
  columns: Columns | Partial<Columns> = {};
  rows: Row[] = [];
}

export enum ArchiveFinderStatus {
  DELIVERED = 'deliver',
  RELEASED = 'released',
  DELETED = 'deleted',

  TIMEOUT = 'timeout',
  DISCARD = 'discard',
  RECEIVED = 'received',
  REJECT = 'reject',
  RETRY = 'retry',
  RELAY = 'relay',
}

export interface Columns {
  id: string;
  messageId: string;
  time: string;
  sender: string;
  recipients: string;
  subject: string;
  status: string;
  postfixReturn: string;
}

export interface Row {
  id: string;
  messageId: string;
  time: string;
  sender: string;
  recipients: string[];
  subject: string;
  status: string;
  postfixReturn: any[];
  released_at?: string;
}

export interface ArchiveFinderOne {
  title: string;
  messages: any[];
  fields: Fields;
}

export interface Fields {
  time: string; // |FinderDate
  connection: Connection;
  emailData: EmailData;
  analysis: Analysis;
  sentence: Sentence;
  archiveData: ArchiveData;
  mtaReturn: string;
  gfId: string;
  category: string;
  route: string;
  envelope: Envelope;
  postfixReturn: any[];
  timelineHistory: any[];
  mtoReturn: MtoReturn[];
}

export interface FinderDate {
  date: string;
  timezone_type: number;
  timezone: string;
}

export interface Analysis {
  spf: string;
  dmarc: string;
  virus: string;
  spam: Spam;
}

export interface Spam {
  bulk: number;
  spam: number;
  mkt: number;
}

export interface ArchiveData {
  archived?: boolean;
  messageId: string | null;
  archiveFlags?: string[];
  flags?: number[];
}

export interface Connection {
  ip: string;
  country?: string;
}

export interface EmailData {
  sender_envelope: string;
  subject: string;
  dkim_result: string;
  spf: string;
  language: string;
  ipCountry: string | null;
  dmarcResult: string;
  spfDetails: string;
  badFormatted: boolean;
  path: string;
  attachmentsCount: number;
  size: number;
  mailRoute: string;
  sender: string;
  recipients: string[];
}

export interface Sentence {
  disposition: {
    name: string;
    message: string;
  };
  rulesMatched: RulesMatched;
  actionsExecuted: string[];
}

export interface RulesMatched extends GenericItems {
  firewall?: string[];
  virus?: string[];
  spam?: any[];
}

export interface Envelope {
  client_ehlo: string;
  gfid: string;
  all_recipients_list: string[];
  client_port: number;
  invalids: any[];
  route: string;
  size: number;
  client_resolve: true,
  client_helo: string;
  all_recipients: string;
  sender: string;
  recipients: string;
  client_protocol: string;
  client_address: string;
  client_reverse: null
}

export interface MtoReturn {
  gfid: string;
  timestamp: number,
  sender: string;
  recipients: string[];
  server: string;
  banner: string;
  result: string;
  logs: string[];
}
