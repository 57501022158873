import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

import { SqlDateModel } from '../../_models/sql-date/sql-date.model';
import * as Moment from 'moment';
import { FinderDate } from '../../../gws/email/models/archive-finder.model';
import { DateTimeModel } from '../../_ui/models/form/date-time.model';

interface DateReplacer {
  regExp: RegExp;
  replace: string;
}

@Pipe({
  name: 'sqlDate',
})
export class SqlDatePipe implements PipeTransform {
  private readonly MINUTES_IN_ONE_DAY = 60;
  private readonly MAXIMUM_DATE_STRING_SIZE = 19;

  public dates: DateReplacer[] = [
    {
      regExp: /^(\d{4}-\d{2}-\d{2})$/,
      replace: '$1T00:00:00-03:00',
    },
    {
      regExp: /^(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})$/,
      replace: '$1T$2-03:00',
    },
    {
      regExp: /^(\d{4}-\d{2}-\d{2}) (\d:\d{2}:\d{2})$/,
      replace: '$1T0$2-03:00',
    },
  ];

  public transform(
    date: string|any,
    format: SqlDateModel | string = SqlDateModel.DATE,
    locale: string = 'en-US',
  ): string {
    if (typeof date === 'object') {
      const _date = new Date(date?.date + (date?.timezone ?? ''));
      date = _date.toISOString();
    } else {
      date = date.toString();
    }
    const userTimezone = new Date().getTimezoneOffset() / this.MINUTES_IN_ONE_DAY;
    const momentDate = Moment(date.substring(0, this.MAXIMUM_DATE_STRING_SIZE)).locale(locale);
    momentDate.subtract(userTimezone, 'hours');

    return momentDate.format(format);
  }
}
