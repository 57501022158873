import { Injectable, Input, Directive } from '@angular/core';

import { Fields } from '../../../models/archive-finder.model';
import { Flag } from '../../../../administration/archive/flags/models/flag.model';

@Directive()
@Injectable({ providedIn: 'root' })
export class ModalTabsBaseDirective {
  @Input() id = null;
  @Input() readonly = false;
  @Input() fields: Fields;
  @Input() loading = true;
  @Input() flags: Flag[];

  constructor() {}
}
