import { isNumber } from 'lodash';

class EnvDefinition {
  private FULL_ENV_VARS: any;

  public set envVars(value: any) {
    this.FULL_ENV_VARS = value;
  }

  public get envVars(): any {
    return this.FULL_ENV_VARS;
  }

  public get(key: string, defaultValue: any = null): any {
    return JSON.stringify(this.FULL_ENV_VARS[key]) || defaultValue;
  }
}

export const Env = new EnvDefinition();

export function env(key: string, defaultValue: any = null): any {
  const value = Env.get(key, defaultValue);

  if (isNumber(value)) {
    return value as number;
  }

  if (typeof value === 'string') {
    switch (value.toLowerCase()) {
      case 'true':
      case '(true)':
        return true;
      case 'false':
      case '(false)':
        return false;
      case 'empty':
      case '(empty)':
        return '';
      case 'null':
      case '(null)':
        return null;
    }
  }

  return value;
}
