import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as Moment from 'moment';

import { BaseListDirective } from '../../../../core/_ui/components/list/base-list.component';
import { TrackerService } from '../../services/tracker/tracker.service';
import { EmailTrackerModalComponent } from '../../components/email-tracker-modal/email-tracker-modal.component';
import { EmailFilterTracker } from '../../models/email-filter-tracker';
import { EmailQuickFinderComponent } from '../../components/email-quick-finder/email-quick-finder.component';
import { SqlDateModel } from '../../../../core/_models/sql-date/sql-date.model';
import { cleanList, FieldListModel } from '../../../../core/_ui/models/list/field-list.model';
import { ArchiveFinderModel, ArchiveFinderStatus, Columns, Row } from '../../models/archive-finder.model';
import { TranslatePipe } from '../../../../core/_pipes/pipes/translate.pipe';
import { HttpClient } from '@angular/common/http';
import { MessageLocale, MessageType } from 'src/app/gws/shared/message/models/message.model';
import { SearchHelpModalComponent } from '../../components/search-help-modal/search-help-modal.component';
import { FlagService } from '../../services/flag/flag.service';
import { Flag } from 'src/app/gws/administration/archive/flags/models/flag.model';
import { modalDefaultOptions } from 'src/app/core/_models/modal/modal-default-options.model';
import { TypeReport } from '../../models/type-report';
import { ArchiveFlagActionComponent } from '../../components/email-action/archiveFlags/archive-flag-action.component';
import { QuarantineService } from '../../services/quarantine/quarantine.service';
import { EmailRedirectComponent } from '../../components/email-redirect/email-redirect.component';
import { TypeActionArchive } from '../../models/type-action-archive';
import { StorageService } from 'src/app/core/_services/storage.service';
import { customerId } from 'src/app/core/_helpers/util';
import { Base64 } from 'js-base64';
import { EmailAction } from '../../models/email-action.model';
import { ColumnsTypeModel } from '../../../shared/list/models/columns-type.model';
import { SortListModel } from '../../../shared/list/models/sort-list.model';

@Component({
  selector: 'app-email-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.scss'],
})
export class EmailTrackerComponent
  extends BaseListDirective
  implements OnInit, OnDestroy {
  @ViewChild('quickFinder') private _textComponent: EmailQuickFinderComponent;
  public csv;
  private _maxProg = 100;
  private _timerStep = 0;
  public currentTime = this._maxProg;
  public interval;
  public lineClick = null;
  public masterCheckbox = [];
  public listCheckboxes = [];
  public filter: EmailFilterTracker;
  public sqlDateModel = SqlDateModel;
  public massActionsActive = false;
  public updateEvent = false;
  public changeStyle: boolean;
  public checked = 0;
  public flags: Flag[] = [];
  public isSearch = false;
  public checkboxInfo: {
    model: boolean[];
    list: any;
  };
  public listColumns: Partial<Columns> = {
    status: 'status',
    sender: 'sender',
    subject: 'subject',
  };
  public buttonLoading: boolean;
  public toSave;
  public intermediaryCheckbox: boolean;
  public av: boolean;

  public list: ArchiveFinderModel;

  constructor(
    private trackerService: TrackerService,
    private _storageService: StorageService,
    private quarantineService: QuarantineService,
    private ngbModal: NgbModal,
    private _http: HttpClient,
    protected _translate: TranslatePipe,
    protected _flagService: FlagService,
  ) {
    super(ngbModal, trackerService);
    this.setComponent(EmailTrackerModalComponent);
    this.reloadOnModalClose = true;
    this.lineClick = (event, row, index) => {
      this.actionList({
        action: 'show',
        id: row.id,
      });
    };
  }

  ngOnInit(): void {
    this.changeStyle = false;
    this._flagService.getEmailTrackerFlags().subscribe((flags) => {
      this.flags = flags;
    });
    const filter = new EmailFilterTracker();
    filter.startDate = Moment().subtract(29, 'days');
    filter.endDate = Moment();
    this.setSubscribes();
  }

  sortAction(event: SortListModel) {
    this.filter.orderBy = event.active + ',' + event.direction;
    if (event.direction === '') {
      this.filter.orderBy = null;
    }
    this.getList(this.currentPage, 0, true);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.resetInterval();
  }

  @HostListener('window:scroll')
  public onScroll(): void {
    this.checkWindowYOffset();
  }

  public showFlags(flagId: any, separator = ', '): string {
    const flagNames = [];
    let element;
    let flagID;
    for (let i = 0; i < flagId.length; i++) {
      element = this.flags.find((flag) => {
        flagID = flagId[i].toString();
        return flag.id === flagID;
      });
      if (element) {
        flagNames.push(element.name);
      }
    }
    return flagNames.join(separator);
  }

  public refreshList() {
    this.interval = setInterval(() => {
      this.currentTime -= this._timerStep / 2;
      if (this.currentTime < 0) {
        clearInterval(this.interval);
        this.currentTime = this._maxProg;
        this.getList(this.currentPage);
      }
    }, 300);
  }

  public getList(
    page: number = 1,
    optionalWaiting = 0,
    quickRefresh = false,
  ): void {
    this.resetInterval();
    if (!this.updateEvent && !quickRefresh) {
      this.loading = true;
    }
    this.filter.page = page;
    this.filter.maxPerPage = this.currentPageSize;
    setTimeout(() => {
      this.trackerService
        .getResult(this.filter, this.filter.searchAll)
        .subscribe({
          next: (result: ArchiveFinderModel) => {
            this.list = result;
            this.list.columns = this.listColumns;
            this.list.rows.forEach((row: Row) => {
              if (row.released_at && row.status !== ArchiveFinderStatus.DELETED) {
                row.status = ArchiveFinderStatus.RELEASED;
              }
            });
            this._normalizeStatus();
            this._makeFlagsReadable();
            this.loading = false;
            this.updateEvent = false;
            this.refreshList();
          },
          error: () => {
            this.list = cleanList as ArchiveFinderModel;
            this.list.columns = this.listColumns;
            this.loading = false;
            this.updateEvent = false;
            this.refreshList();
          },
        });
    }, optionalWaiting);
  }

  public search(event?: EmailFilterTracker) {
    if (event) {
      this.filter = event;
    }

    this.getList();
    this._isSearch().then((res) => {
      this.isSearch = res;
    });

    this.masterCheckbox = [];
    this.listCheckboxes = [];
    this.checked = 0;
    this.currentPage = 1;
    this.massActionsActive = false;
  }

  public searchHelpModalOpen(): void {
    this._ngbModal.open(SearchHelpModalComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      windowClass: 'ngb-modal-component',
    });
  }

  public pagination(event) {
    this.checked = 0;
    this.filter = this._textComponent.getFilter();
    super.pagination(event);
  }

  public checkboxClick(data: { checkboxModel; list; row?; index? }): void {
    this.intermediaryCheckbox = this._hasCheckboxSelected(data.checkboxModel);
    this.massActionsActive = this._hasCheckboxSelected(data.checkboxModel);
    this.checked = data.checkboxModel.filter((item) => item === true).length;
    this.checkboxInfo = {
      model: data.checkboxModel,
      list: data.list,
    };
  }

  public doAction(event: EmailAction) {
    switch (event) {
      case EmailAction.ExportData:
        return this.exportData();
      case EmailAction.Release:
        this.av = false;
        return this.releaseSelected();
      case EmailAction.ReleaseAV:
        this.av = true;
        return this.releaseSelected();
      case EmailAction.Delete:
        return this.deleteEmails();
      case EmailAction.Redirect:
        return this.redirect();
      case EmailAction.Phishing:
        return this.reportMessages(TypeReport.PHISHI);
      case EmailAction.Spam:
        return this.reportMessages(TypeReport.SPAM);
      case EmailAction.NotSpam:
        return this.reportMessages(TypeReport.HAM);
      case EmailAction.SenderBlocked:
        return this.senderToBlockedOrTrustedList(0);
      case EmailAction.SenderTrusted:
        return this.senderToBlockedOrTrustedList(1);
    }
  }

  public exportData() {
    this.sweetAlertService.alert(
      {
        title: 'CSV_export',
        text: 'CSV_export_warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.trackerService
            .getResult(
              {
                maxPerPage: 10000,
                startDate: undefined,
                endDate: undefined,
                subject: '',
                sender: '',
                recipients: '',
                status: '',
                RELEASED_AT: false,
                flags: '',
                page: 1,
                orderBy: '',
                emailAddress: '',
                searchAll: '',
                senderMTA: '',
                senderHeader: '',
              },
              '',
            )
            .toPromise()
            .then((result: any) => {
              let items = result.rows;
              items = this.getFlagsName(items);
              const replacer = (key, value) => (value === null ? '' : value);
              const header = Object.keys(items[0]);
              const csv = [
                header.join(','),
                ...items.map((row) =>
                  header
                    .map((fieldName) => {
                      let curRow = JSON.stringify(row[fieldName], replacer);
                      //tratando tempo
                      if (
                        fieldName === 'time' ||
                        (fieldName === 'released_at' && curRow.length > 1)
                      ) {
                        curRow = curRow.replace('+00:00', '');
                        curRow = curRow.replace('T', ' ');
                      }

                      //tratando campo released
                      if (fieldName === 'status') {
                        if (row['released_at'] !== null) {
                          curRow = 'released';
                        }
                      }

                      //tratando vetores
                      if (curRow[0] === '[' || fieldName === 'flags') {
                        const arr = JSON.parse(curRow);
                        let newString = '';
                        if (arr.length > 0) {
                          if (typeof arr[0] === 'string') {
                            newString = this.concatString(1, arr);
                          } else {
                            if (typeof arr[0] === 'object') {
                              newString = this.concatString(2, arr);
                            }
                            if (typeof arr[0] === 'number') {
                              //tratando flags
                              newString = this.showFlags(arr, '; ');
                            }
                          }
                        }
                        curRow = newString;
                      }
                      return curRow;
                    })
                    .join(','),
                ),
              ].join('\r\n');
              this.csv = csv;
            })
            .catch((error) => {
              this.csv = 'error';
            });
        },
      },
      (result) => {
        this._textComponent.actionsForm.setValue({
          action: [-1],
        });
        if (result.isConfirmed) {
          if (this.csv !== 'error') {
            this.download();
            this._messageService?.setMessageConfig(
              this._translate.transform('CSV_exported', 'fe'),
              MessageType.success,
              MessageLocale.emailTracker,
            );
          } else {
            this._messageService?.setMessageConfig(
              this._translate.transform('CSV_error', 'errors'),
              MessageType.error,
              MessageLocale.emailTracker,
            );
          }
        }
      },
    );
  }

  public concatString(type: number, array) {
    let newString = '';
    switch (type) {
      case 1:
        array.forEach((el, i) => {
          if (i === array.length - 1) {
            newString = newString + el;
          } else {
            newString = newString + el + '; ';
          }
        });
        return newString;
      case 2:
        array.forEach((el) => {
          Object.entries(el).forEach((field, i) => {
            if (i === Object.entries(el).length - 1) {
              newString = newString + field[0] + ': ' + field[1] + '; ';
            } else {
              newString = newString + field[0] + ': ' + field[1] + '  ';
            }
          });
        });
        return newString;
      default:
        break;
    }
  }

  public download() {
    const a = document.createElement('a');
    const emailB64 = Base64.encode(this.csv);
    const blob = this.b64toBlob(emailB64, 'text/plain;charset=utf-8');
    const blobUrl = URL.createObjectURL(blob);
    a.href = blobUrl;
    a.download = 'tracker-data.csv';
    a.click();
  }

  public getFlagsName(items) {
    items.forEach((element) => {
      element.releaseHistory?.forEach((element) => {
        delete element.admin_id;
      });
      delete element.id;
    });
    return items;
  }

  public b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = Base64.atob(b64Data);
    const byteArrays = [];
    byteArrays.push(new Uint8Array([0xef, 0xbb, 0xbf]));
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });

    return blob;
  }

  public manageFlags() {
    const dialog: NgbModalRef = this.ngbModal.open(ArchiveFlagActionComponent, {
      size: 'sm',
      windowClass: 'modal-xsm custom',
      backdrop: 'static',
      keyboard: false,
    });
    const emails = [];
    this.checkboxInfo.model.forEach((isSelected, index) => {
      if (isSelected) {
        const rows = this.checkboxInfo.list.rows;
        const currentFlags = [];
        let tempFlag;

        rows[index].flags.forEach((flag) => {
          tempFlag = this.flags.find((f) => +f.id === +flag);
          currentFlags.push({ id: +tempFlag.id, name: tempFlag.name });
        });

        const selectedEmail = {
          esId: rows[index].id,
          id: rows[index].messageId,
          currentFlags,
        };

        emails.push(selectedEmail);
      }
    });
    dialog.componentInstance.selectedEmails = emails;
    dialog.componentInstance.saveEvent.subscribe((toSave) => {
      if (toSave.length > 0) {
        this.quarantineService
          .saveFlags({ emails: toSave })
          .subscribe({
            next: (success) => {
              this._messageService?.setMessageConfig(
                success.message,
                MessageType.success,
                MessageLocale.emailTracker,
              );
              this.getList(this.currentPage);
            },
            error: (error) => {
              this._messageService?.setMessageConfig(
                error.message,
                MessageType.error,
              );
            },
          })
          .add(() => {
            dialog.close();
            this.resetCheckboxes();
          });
      } else {
        dialog.close();
      }
    });
    dialog.result.finally(() => {
      this._textComponent.actionsForm.setValue({
        action: [-1],
      });
    });
  }

  public resetCheckboxes() {
    this.checkboxInfo.model.fill(false);
    this.checked = 0;
    this.massActionsActive = false;
    this.resetScroll();
    this.buttonLoading = false;
    this._textComponent.actionsForm.setValue({
      action: [-1],
    });
  }

  public reportMessages(type: TypeReport): void {
    const ids = [];
    this.pushCheckedElementsToArray(ids);

    this.quarantineService
      .reportMessages({ messagesIds: ids }, type)
      .subscribe({
        next: (result) => {
          this._messageService?.setMessageConfig(
            result.message,
            MessageType.success,
            MessageLocale.emailTracker,
          );
          this.getList(this.currentPage);
        },
        error: (error) => {
          this._messageService?.setMessageConfig(
            error.message,
            MessageType.error,
          );
        },
      })
      .add(() => {
        this.resetCheckboxes();
      });
  }

  public deleteEmails() {
    const ids = [];

    this.pushCheckedElementsToArray(ids);

    this.quarantineService
      .removeMessages({ messagesIds: ids })
      .subscribe({
        next: (result) => {
          this._messageService?.setMessageConfig(
            result.message,
            MessageType.success,
            MessageLocale.emailTracker,
          );
          this.getList(this.currentPage);
        },
        error: (error) => {
          this._messageService?.setMessageConfig(
            error.message,
            MessageType.error,
          );
        },
      })
      .add(() => {
        this.resetCheckboxes();
      });
  }

  public redirect() {
    const ids = [];
    const esIds = [];
    const recipients = [];

    this.pushCheckedElementsToArray(ids, esIds);

    const dialog: NgbModalRef = this.ngbModal.open(EmailRedirectComponent, {
      size: 'sm',
      windowClass: 'modal-xsm custom',
      backdrop: 'static',
      keyboard: false,
    });

    dialog.result.finally(() => {
      this._textComponent.actionsForm.setValue({
        action: [-1],
      });
    });

    dialog.componentInstance.eventSave.subscribe((result) => {
      result.forEach((r) => {
        recipients.push(r);
      });
      this.quarantineService
        ?.executeMessageAction(
          { elasticIds: esIds, messagesIds: ids, recipients },
          TypeActionArchive.REDIRECT,
        )
        .subscribe({
          next: (success) => {
            this._messageService?.setMessageConfig(
              success.message,
              MessageType.success,
              MessageLocale.emailTracker,
            );
            this.getList(this.currentPage);
          },
          error: (error) => {
            this._messageService?.setMessageConfig(
              error.message,
              MessageType.error,
            );
          },
        })
        .add(() => {
          dialog.close();
          this.resetCheckboxes();
        });
    });
  }

  public pushCheckedElementsToArray(a?: any[], b?: any[]) {
    this.checkboxInfo.model.forEach((isSelected, index) => {
      if (isSelected) {
        const rows = this.checkboxInfo.list.rows;
        a?.push(rows[index].messageId);
        b?.push(rows[index].id);
      }
    });
  }

  public senderToBlockedOrTrustedList(safe: number) {
    const selectedElasticIds = [];
    this.pushCheckedElementsToArray([], selectedElasticIds);

    const token = JSON.parse(this._storageService.getItem('token'));

    const list = safe ? 'trusted' : 'blocked';

    this.buttonLoading = true;
    this.loading = true;

    this._http
      .post('v1/' + list + '-lists/via-tracker/customer/' + customerId(token), {
        elasticIds: [...selectedElasticIds],
        safe: safe,
        type: 'Sender',
      })
      .subscribe({
        next: (res: any) => {
          this._messageService?.setMessageConfig(
            res.message,
            MessageType.success,
            MessageLocale.emailTracker,
          );
          this._messageService?.setMessageConfig(
            res.errors[0],
            MessageType.error,
            MessageLocale.emailTracker,
          );
          this.getList(this.currentPage, 1000);
        },
        error: (error) => {
          this._messageService?.setMessageConfig(
            error.arrayErrorMessages[0],
            MessageType.error,
            MessageLocale.emailTracker,
          );
          this.loading = false;
        },
      })
      .add(() => {
        this.resetCheckboxes();
      });
  }

  public releaseSelected(): void {
    const selectedEmailIds = [];
    const selectedElasticIds = [];
    this.pushCheckedElementsToArray(selectedEmailIds, selectedElasticIds);

    this.buttonLoading = true;
    this.loading = true;

    this._http
      .post('/v1/archive-messages/release', {
        messagesIds: [...selectedEmailIds],
        elasticIds: [...selectedElasticIds],
        av: this.av,
      })
      .subscribe({
        next: (res) => {
          const response: any = res;
          this._messageService?.setMessageConfig(
            response.message,
            MessageType.success,
            MessageLocale.emailTracker,
          );
          this.getList(this.currentPage, 1000);
        },
        error: (error) => {
          const message = error.message ?? error.errors;
          this._messageService?.setMessageConfig(
            message,
            MessageType.error,
            MessageLocale.emailTracker,
          );
          this.loading = false;
        },
      })
      .add(() => {
        this.resetCheckboxes();
      });
  }

  public resetScroll(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  public resetInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.currentTime = this._maxProg;
  }

  public manipulateRecord(id = null, copy = false, readonly = false): void {
    const dialog: NgbModalRef = this._ngbModal.open(
      this.component,
      modalDefaultOptions,
    );

    this.resetInterval();

    dialog.componentInstance.permissions = this.list.permissions;
    dialog.componentInstance.readonly = readonly;

    if (id !== null) {
      const locate = copy ? 'copyId' : 'id';
      dialog.componentInstance[locate] = id;
    }

    dialog.result.then(
      () => {
      },
      () => {
        if (this.reloadOnModalClose) {
          this.updateEvent = true;
          this.getList(this.currentPage);
        }
      },
    );
    this.dialog = dialog;
  }

  public switchTimer() {
    this._timerStep++;
    if (this._timerStep > 2) this._timerStep = 0;
  }

  public checkWindowYOffset() {
    const pageOffset = window.pageYOffset;
    this.changeStyle = pageOffset > 179 ? true : false;
  }

  public get columns(): ColumnsTypeModel[] {
    return [
      { id: 'status', label: 'status' },
      { id: 'sender', label: 'sender' },
      { id: 'subject', label: 'subject' },
      { id: 'recipients', label: 'recipient' },
      { id: 'readableFlag', label: 'readableFlag' },
      { id: 'time', label: 'digest_date', isFullDate: true },
    ];
  }

  protected _normalizeStatus(): void {
    this.list.rows.forEach((archiveRow: Row) => {
      if (archiveRow.status && archiveRow.status.length) {
        archiveRow.status = this._translate.transform(
          'rules_' + archiveRow.status.toLowerCase(),
          'fe',
        );
      } else {
        archiveRow.status = '-';
      }
    });
  }

  private _makeFlagsReadable(): void {
    this.list.rows.forEach((listRow) => {
      const row = listRow as any;
      row.readableFlag = [];
      row.flags.forEach((flag) => {
        row.readableFlag.push(
          this.flags.find((element) => +element.id === +flag)?.name,
        );
      });
    });
  }

  private _hasCheckboxSelected(checkboxModel: any[]): boolean {
    return !!checkboxModel.filter(Boolean).length;
  }

  private _isSearch(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(!!document.querySelector('.tag'));
      });
    });
  }
}
