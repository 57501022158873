<div class="card">
  <div class="circle-icon circle-icon-{{ actionName.toLowerCase() }}">
    <i
      class="{{ getIconPrefix() }} fa-{{ getIcon() }}"
    ></i>
  </div>
  <div class="card-info">
    <div class="timeline-info">
      <div class="timeline-card-title">
        {{ actionName + '_title' | translate: 'titles' }}
        <small *ngIf="actionName === timelineAction.ACTION_REPORT && message.action !== timelineAction.ACTION_REPORT">
          ({{ 'tlAction' + message.action  | translate: 'titles' }})
        </small>
        <small *ngIf="(actionName === timelineAction.ACTION_TRUST || actionName === timelineAction.ACTION_BLOCK)
         && message?.action !== timelineAction.ACTION_TRUST && message?.action !== timelineAction.ACTION_BLOCK">
          ({{ 'tlAction' + message.action  | translate: 'titles' }})
        </small>
        <small *ngIf="message?.action === timelineAction.ACTION_RELEASE">
          ({{ 'tlActionReleaseNoAv'  | translate: 'titles' }})
        </small>
      </div>
      <div class="date" *ngIf="!!this.message?.time">
        <i class="far fa-clock"></i>
        {{ formatDate() }}
      </div>
    </div>
    <div
      class="redirect-row"
      *ngIf="message?.action === timelineAction.ACTION_REDIRECT && message?.redirects.length > 0"
    >
      {{ 'recipients' | translate }}:
      {{ formatRedirects() }}
    </div>
    <div class="author-row" *ngIf="message.author">
      <p>{{ 'by_timeline' | translate: 'labels' }}: {{ message.author }}</p>
      <p class="text-right">
        {{ 'from_timeline' | translate: 'labels' }}: {{ message.environment }}
        {{
          message?.environment === environment.GWS
            ? ('from_userTypeAdmin' | translate: 'labels')
            : ( (message?.fromDigest)
              ? ('from_userTypeEndUserDigest' | translate: 'labels')
              : ('from_userTypeEndUser' | translate: 'labels')
            )
        }}
      </p>
    </div>
  </div>
</div>
<ng-content></ng-content>
