<app-loading *ngIf="loading; else details"></app-loading>
<ng-template #details>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'email_tracker_details_title' | translate: 'titles' }}
    </h4>
    <button
      (click)="ngbActiveModal.dismiss()"
      aria-label="Close"
      class="close"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-tab-group mat-align-tabs="center" animationDuration="0ms">
      <mat-tab id="emt-modal-details">
        <ng-template mat-tab-label>
          <span
            class="fas fa-search tab-icon"
            data-placement="top"
            data-toggle="tooltip"
            title="Details"
          ></span>
          {{ 'email_tracker_details' | translate: 'titles' }}
        </ng-template>
        <ng-template matTabContent>
          <app-email-tracker-modal-details
            [fields]="fields"
            [flags]="flags"
            [id]="emtId"
            [loading]="loading"
            [readonly]="readonly"
          >
            <div class="timeline-container w-100">
              <div class="form-row subtitle mb-4">
                <label>{{ 'timeline' | translate: 'labels' }}</label>
                <label class="description">
                  {{ 'timeline_description' | translate: 'labels' }}
                </label>
              </div>
              <app-loading *ngIf="!timeline"></app-loading>
              <app-timeline-card
                *ngFor="let card of timeline; last as isLast"
                [message]="card"
              >
                <div *ngIf="!isLast" class="connecting-line"></div>
              </app-timeline-card>
            </div>
          </app-email-tracker-modal-details>
        </ng-template>
      </mat-tab>
      <mat-tab id="emt-modal-verdict">
        <ng-template mat-tab-label>
          <span
            class="fas fa-check-square tab-icon"
            data-placement="top"
            data-toggle="tooltip"
            title="Email"
          ></span>
          {{ 'email_tracker_verdict' | translate: 'titles' }}
        </ng-template>
        <ng-template matTabContent>
          <app-email-tracker-modal-verdict
            [fields]="fields"
            [id]="emtId"
            [loading]="loading"
            [readonly]="readonly"
          ></app-email-tracker-modal-verdict>
        </ng-template>
      </mat-tab>
      <mat-tab id="emt-modal-mta">
        <ng-template mat-tab-label>
          <span
            class="fas fa-exchange-alt tab-icon"
            data-placement="top"
            data-toggle="tooltip"
            title="MTA Results"
          ></span>
          {{ 'email_tracker_mta' | translate: 'titles' }}
        </ng-template>
        <ng-template matTabContent>
          <app-email-tracker-modal-mta
            [fields]="fields"
            [id]="emtId"
            [loading]="loading"
            [readonly]="readonly"
          ></app-email-tracker-modal-mta>
        </ng-template>
      </mat-tab>
      <mat-tab id="emt-modal-read">
        <ng-template mat-tab-label>
          <span
            class="fas fa-envelope-open tab-icon"
            data-placement="top"
            data-toggle="tooltip"
            title="Email"
          ></span>
          {{ 'email_tracker_read' | translate: 'titles' }}
        </ng-template>
        <ng-template matTabContent>
          <app-email-tracker-modal-read
            [fields]="fields"
            [flags]="flags"
            [id]="emtId"
            [loading]="loading"
            [readonly]="readonly"
            (readActionExecuted)="updateFields()"
          ></app-email-tracker-modal-read>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="modal-footer">
    <button
      (click)="ngbActiveModal.dismiss('Close click')"
      class="btn btn-primary"
      type="button"
    >
      {{ 'btn_close' | translate }}
    </button>
  </div>
</ng-template>
