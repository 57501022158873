import { Component, Input, OnInit } from '@angular/core';
import * as Moment from 'moment';
import { ArchiveFinderStatus } from '../../models/archive-finder.model';
import {
  ArchiveFinderTimelineAction, ArchiveFinderTimelineEnvironment,
  ArchiveFinderTimelineFieldModel,
} from '../../models/archive-finder-timeline.model';

@Component({
  selector: 'app-timeline-card',
  templateUrl: './timeline-card.component.html',
  styleUrls: ['./timeline-card.component.scss'],
})
export class TimelineCardComponent implements OnInit {
  @Input() message: ArchiveFinderTimelineFieldModel;

  public timelineAction = ArchiveFinderTimelineAction;
  public environment = ArchiveFinderTimelineEnvironment;

  private currentActionName: string = '';

  constructor() {
  }

  ngOnInit(): void {
    this.currentActionName = this.getActionName();
  }

  public get actionName(): string {
    if (this.currentActionName === '') {
      this.currentActionName = this.getActionName();
    }
    return this.currentActionName;
  }

  private getActionName(): string {
    if (this.message?.action) {
      switch (this.message.action) {
        case ArchiveFinderTimelineAction.ACTION_RELEASE:
        case ArchiveFinderTimelineAction.ACTION_RELEASE_AV:
          return ArchiveFinderStatus.RELEASED;
        case ArchiveFinderTimelineAction.ACTION_DELETE:
          return ArchiveFinderStatus.DELETED;

        // Novas ações:
        case ArchiveFinderTimelineAction.ACTION_REDIRECT:
          return ArchiveFinderTimelineAction.ACTION_REDIRECT;
        case ArchiveFinderTimelineAction.ACTION_REPORT:
        case ArchiveFinderTimelineAction.ACTION_REPORT_NOT_SPAM:
        case ArchiveFinderTimelineAction.ACTION_REPORT_SPAM:
        case ArchiveFinderTimelineAction.ACTION_REPORT_PHISH:
          return ArchiveFinderTimelineAction.ACTION_REPORT;
        case ArchiveFinderTimelineAction.ACTION_TRUST:
        case ArchiveFinderTimelineAction.ACTION_TRUST_SENDER:
        case ArchiveFinderTimelineAction.ACTION_TRUST_RECIPIENT:
          return ArchiveFinderTimelineAction.ACTION_TRUST;
        case ArchiveFinderTimelineAction.ACTION_BLOCK:
        case ArchiveFinderTimelineAction.ACTION_BLOCK_SENDER:
        case ArchiveFinderTimelineAction.ACTION_BLOCK_RECIPIENT:
          return ArchiveFinderTimelineAction.ACTION_BLOCK;
      }
    }

    return this.message.name;
  }

  public getIcon(): string {
    switch (this.actionName) {
      case ArchiveFinderStatus.RELEASED:
        return 'share-square';
      case ArchiveFinderStatus.DELETED:
        return 'trash-alt';
      case ArchiveFinderStatus.TIMEOUT:
        return 'times';
      case ArchiveFinderStatus.DELIVERED:
        return 'envelope-open';
      case ArchiveFinderStatus.DISCARD:
        return 'trash-alt';
      case ArchiveFinderStatus.RECEIVED:
        return 'envelope';
      case ArchiveFinderStatus.REJECT:
        return 'ban';
      case ArchiveFinderStatus.RETRY:
        return 'undo';
      case ArchiveFinderStatus.RELAY:
        return 'exchange-alt';

      case ArchiveFinderTimelineAction.ACTION_REDIRECT:
        return 'exchange-alt';
      case ArchiveFinderTimelineAction.ACTION_REPORT:
        return 'flag';
      case ArchiveFinderTimelineAction.ACTION_TRUST:
        return 'thumbs-up';
      case ArchiveFinderTimelineAction.ACTION_BLOCK:
        return 'thumbs-down';
    }
  }

  public getIconPrefix(): string {
    switch (this.actionName) {
      case ArchiveFinderStatus.REJECT:
        return 'fas';
      case ArchiveFinderStatus.RETRY:
        return 'fas';
      case ArchiveFinderStatus.RELAY:
      case ArchiveFinderTimelineAction.ACTION_REDIRECT:
        return 'fas';
      default:
        return 'far';
    }
  }

  public formatDate(): string {
    return Moment.utc(this.message.time, 'YYYY/MM/DD hh:mm:ss')
      .local()
      .format('hh:mm a - DD/MM');
  }

  public formatRedirects(): string {
    return this.message.redirects.join(', ');
  }
}
