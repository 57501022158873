import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from './app/app-injector.service';
import { Env } from './env';

declare let FULL_ENV_VARS: string;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((moduleRef) => {
    try {
      Env.envVars = FULL_ENV_VARS;
    } catch (e) {
      console.error('Error setting environment variables', e);
    }
    AppInjector.setInjector(moduleRef.injector);
  })
  .catch(err => console.error(err));
