import { ArchiveFinderStatus } from './archive-finder.model';

export enum ArchiveFinderTimelineAction {
  ACTION_RELEASE = 'RELEASE_WITHOUT_AV',
  ACTION_RELEASE_AV = 'RELEASE_WITH_AV',
  ACTION_DELETE = 'DELETE',
  ACTION_REDIRECT = 'REDIRECT',
  ACTION_REPORT = 'REPORT', // preferencialmente utilizar as constantes abaixo
  ACTION_REPORT_NOT_SPAM = 'REPORT_HAM',
  ACTION_REPORT_SPAM = 'REPORT_SPAM',
  ACTION_REPORT_PHISH = 'REPORT_PHISHING',

  // Ações de Trust e Block list
  ACTION_TRUST = 'THRUST', // TRUST está escrito errado, mas foi mantido para manter a compatibilidade do sistema
  ACTION_TRUST_SENDER = 'THRUST_SENDER',
  ACTION_TRUST_RECIPIENT = 'THRUST_RECIPIENT',
  ACTION_BLOCK = 'BLOCK',
  ACTION_BLOCK_SENDER = 'BLOCK_SENDER',
  ACTION_BLOCK_RECIPIENT = 'BLOCK_RECIPIENT',
}

/**
 * Exemplo de objeto de timeline:
 *
 * <code>
 * {
 *   "title": "",
 *   "messages": [],
 *   "fields": [
 *     {
 *       "name": "received",
 *       "time": "2024-11-06T19:22:47+00:00"
 *     },
 *     {
 *       "name": "reject",
 *       "time": "2024-11-06T19:22:47+00:00"
 *     },
 *     {
 *       "action": "REPORT_PHISHING",
 *       "name": "",
 *       "author": "Gatefy - DEV",
 *       "email": "dev@gatefy.com",
 *       "adminId": null,
 *       "mailboxId": 2,
 *       "environment": "EUWA",
 *       "fromDigest": true,
 *       "time": "2024-11-06T19:23:20+00:00"
 *       "redirects": []
 *     }
 *   ]
 * }
 * </code>
 */
export class ArchiveFinderTimelineModel {
  messages: string[];
  title: string;
  fields: ArchiveFinderTimelineFieldModel[];
}

export enum ArchiveFinderTimelineEnvironment {
  GWS = 'GWS',
  EUWA = 'EUWA',
}

export interface ArchiveFinderTimelineFieldModel {
  action?: ArchiveFinderTimelineAction | string;
  name: ArchiveFinderStatus | string;
  author?: string;
  email?: string;
  adminId?: number | string | null;
  mailboxId?: number | string | null;
  environment?: ArchiveFinderTimelineEnvironment | string;
  fromDigest?: boolean;
  redirects?: string[];
  time: string;
}
