import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArchiveFinderModel, Fields } from '../../models/archive-finder.model';
import { EmailFilterTracker } from '../../models/email-filter-tracker';
import * as Moment from 'moment';
import { ArchiveFinderTimelineModel } from '../../models/archive-finder-timeline.model';

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  constructor(private http: HttpClient) {}

  public getOneResult(id: number): Observable<Fields> {
    return this.http
      .get<Fields[]>('/v1/archive-finder/' + id)
      .pipe(map((res: any) => res.fields));
  }

  public getResult(
    filter: EmailFilterTracker = null,
    search: string = ''
  ): Observable<ArchiveFinderModel> {
    const filterString: string = this.filterToSearchString(filter);

    if (filterString) {
      const params = new HttpParams({
        fromObject: {
          filter: filterString,
          search_all:
            search && search.length ? search.trim().toLowerCase() : '',
          page: filter.page.toString(),
        },
      });
      return this.http.get<ArchiveFinderModel>('/v1/archive-finder', { params });
    } else {
      return this.http.get<ArchiveFinderModel>('/v1/archive-finder');
    }
  }

  public getFlags(): Observable<any> {
    return this.http
      .get<any[]>('/v1/archive-finder/form/flags')
      .pipe(map((res: any) => res.rows));
  }

  public getTimeLine(id: string): Observable<ArchiveFinderTimelineModel> {
    return this.http.get<ArchiveFinderTimelineModel>(`v1/archive-finder/timeline/${id}`);
  }

  public getRules(): Observable<any> {
    return this.http
      .get<any[]>('/v1/archive-finder/form/rules')
      .pipe(map((res: any) => res.rows));
  }

  public getMTA(): Observable<any> {
    return this.http
      .get<any[]>('/v1/archive-finder/form/mta')
      .pipe(map((res: any) => res.rows));
  }

  public filterToSearchString(filter: EmailFilterTracker = null): string {
    let param = null;
    if (filter) {
      param = '';

      const insertName = (name: string) => {
        if (param.length) {
          param += '|';
        }
        param += name;
      };

      const insertParam = (value: string, original = false) => {
        param += ',' + (original ? value : value.toString().toLowerCase());
      };

      const validateFilter = (name: string) => {
        if (filter[name]) {
          insertName(name === 'maxPerPage' ? name : name.toLowerCase());
          insertParam(filter[name]);
        }
      };

      const filterConvertDate = (
        date: Moment.Moment,
        complete: '00:00:00' | '23:59:59' = '23:59:59'
      ): string => {
        const dt = date.toDate();
        if (complete === '00:00:00') {
          dt.setHours(0, 0, 0, 0);
        } else {
          dt.setHours(23, 59, 59, 999);
        }
        return Moment(dt).format();
      };

      validateFilter('maxPerPage');
      validateFilter('sender');
      validateFilter('subject');
      validateFilter('recipients');
      validateFilter('flags');
      validateFilter('status');
      validateFilter('RELEASED_AT');
      validateFilter('orderBy');
      validateFilter('emailAddress');
      validateFilter('senderMTA');
      validateFilter('senderHeader');

      if (filter.startDate) {
        insertName('startDate');
        insertParam(Moment(filter.startDate).format(), true);
      }

      if (filter.endDate) {
        insertName('endDate');
        insertParam(Moment(filter.endDate).format(), true);
      }
    }

    return param;
  }
}
