import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { Fields } from '../../models/archive-finder.model';
import { TrackerService } from '../../services/tracker/tracker.service';
import { QuarantineService } from '../../services/quarantine/quarantine.service';
import { Flag } from '../../../administration/archive/flags/models/flag.model';
import { MessageService } from '../../../shared/message/service/message.service';
import {
  ArchiveFinderTimelineFieldModel,
  ArchiveFinderTimelineModel,
} from '../../models/archive-finder-timeline.model';
@Component({
  templateUrl: './email-tracker-modal.component.html',
  styleUrls: ['./email-tracker-modal.component.scss'],
})
export class EmailTrackerModalComponent implements OnInit {
  @Input() id = null;
  @Input() copyId = null;
  @Input() readonly = false;
  loading = true;

  public timeline: ArchiveFinderTimelineFieldModel[];

  emailTrackerService$: Observable<Fields>;
  fields: Fields = null;
  flags: Flag[] = null;

  constructor(
    private trackerService: TrackerService,
    public ngbActiveModal: NgbActiveModal,
    private quarantineService: QuarantineService,
    private messageService: MessageService
  ) {}

  protected _loadEnd() {
    if (this.flags !== null && this.fields !== null) {
      this.loading = false;
    }
  }

  updateTimeline() {
    this.trackerService.getTimeLine(this.id).subscribe({
      next: (res: ArchiveFinderTimelineModel) => {
        this.timeline = res.fields;
        if (!this.timeline[this.timeline.length - 1].time) {
          this.timeline.pop();
        }
      },
      error: () => {
        this.timeline = [];
      },
    });
  }

  ngOnInit() {
    this.getArchiveFlags();
    this.updateTimeline();
    this.emailTrackerService$ = this.trackerService.getOneResult(this.id);
    this.getOne();
    this.messageService.resetMessageService();
  }

  getArchiveFlags(): void {
    this.quarantineService.getArchiveFlags().subscribe((flags: Flag[]) => {
      this.flags = flags;
      this._loadEnd();
    });
  }

  getOne() {
    this.emailTrackerService$.subscribe((response) => {
      this.fields = response;
      this._loadEnd();
    });
  }

  get emtId() {
    return this.copyId ? this.copyId : this.id;
  }

  updateFields() {
    setTimeout(() => {
      this.updateTimeline();
    }, 1000);
  }
}
