import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';

import { searchItems } from '../../models/email-tracker/search-items/search-items.model';
import { SearchTagModel } from '../../models/email-tracker/search-tag/search-tag.model';
import { SearchHelpItem } from '../../models/email-tracker/search-help/search-help-item.model';
import { SearchTagItem } from '../../models/email-tracker/search-tag/ search-tag-item.model';
import { TagMerged } from '../../models/email-tracker/search-tag/tag-merged.model';
import { FlagService } from '../../services/flag/flag.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EmailPoliciesService } from 'src/app/gws/protection/email/email-policies/services/email-policies.service';
import { TranslatePipe } from 'src/app/core/_pipes/pipes/translate.pipe';
import { MatSelectChange } from '@angular/material/select';
import { Flag } from 'src/app/gws/administration/archive/flags/models/flag.model';
import { ArchiveFinderStatus } from '../../models/archive-finder.model';

@Component({
  selector: 'app-search-tag-filter',
  templateUrl: './search-tag-filter.component.html',
  styleUrls: ['./search-tag-filter.component.scss'],
  providers: [NgbTypeaheadConfig],
})
export class SearchTagFilterComponent implements OnInit {
  @Output() emitTag: EventEmitter<SearchTagModel> = new EventEmitter();
  @Output() refresh = new EventEmitter();
  @ViewChild('tagInput') protected _tagInput: ElementRef;
  @ViewChild('ngSelect') protected _ngSelect;
  @Input() public loading: boolean;

  public filters: SearchTagItem[] = [];
  public editing = false;
  public index;
  public flags = [];
  public selectValue;
  public useSelect = false;
  public field;
  public status = [];
  public items = [this.flags, this.status];
  public isRefresh = true;
  public tagForm: FormGroup;
  public filterDisplay: 'status' | 'flags';
  public searchForm: FormGroup = this._formBuilder.group({
    search: [''],
  });

  constructor(
    protected _flagService: FlagService,
    protected _dispositions: EmailPoliciesService,
    protected _formBuilder: FormBuilder,
    protected _translate: TranslatePipe
  ) {}

  ngOnInit(): void {
    this.getFilters();
    this._dispositions.getDefinitions().subscribe((definitions) => {
      this.status.push(...definitions.dispositions.slice(1));
      this.status.push({ id: ArchiveFinderStatus.RELEASED, name: 'Released' });
      this.status.push({ id: ArchiveFinderStatus.DELETED, name: 'Deleted' });
    });
    this._flagService.getEmailTrackerFlags().subscribe((res) => {
      this.flags.push(...res);
    });
  }

  public showOption(option: SearchTagItem): string {
    return option.display.charAt(0).toUpperCase() + option.display.slice(1);
  }

  public emitFromInput(index: string): void {
    if (this._tagInput.nativeElement.value.length || this.editing) {
      const inputText = this._tagInput.nativeElement.value.trim();
      if (this.filters[this.tagForm.value['option']].display !== 'general') {
        const newTagMerged = new TagMerged(this.filters[index].item, inputText);
        this.emitTag.emit(newTagMerged);
      } else {
        this.emitTag.emit(inputText);
      }
      this._tagInput.nativeElement.value = '';
      this.isRefresh = true;
    }
  }

  private getSearchTagItemFromItemName(itemName: string): SearchTagItem | null {
    for (const filter of this.filters) {
      if (filter.item.searchItem === itemName) {
        return filter;
      }
    }
    return null;
  }

  public emitFromSelect(event: MatSelectChange): void {
    const name = event?.value?.name || this.selectValue?.name;
    if (!name) {
      return;
    }
    const searchTagItem = this.getSearchTagItemFromItemName(this.filterDisplay);
    const newTagMerged: TagMerged = new TagMerged(searchTagItem.item, name);

    switch (this.filterDisplay) {
      case 'flags':
        newTagMerged.id = this.flags.find(
          (flag: Flag) => flag.name === newTagMerged.complement
        ).id;
        break;
      case 'status':
        newTagMerged.id = this.status.find(
          (status) => status.name === newTagMerged.complement
        ).id;
        break;
    }

    this.emitTag.emit(newTagMerged);
    setTimeout(() => {
      this.selectValue = null;
      this.searchForm.reset();
    });
  }

  public onAddTag(): void {
    const index = this.tagForm.value['option'];
    if (this._tagInput) {
      this.emitFromInput(index);
    } else if (this.field) {
      this.emitFromSelect(index);
    }

    this.editing = false;
  }

  public cancelEdit(): void {
    this.editing = false;
    if (this._tagInput) {
      this._tagInput.nativeElement.value = '';
    }
    this.field = null;
    this.selectValue = null;
  }

  public checkRefresh(target: 'input' | 'select'): void {
    setTimeout(() => {
      const hasValue =
        target === 'input'
          ? !!this._tagInput?.nativeElement.value
          : !!this.field;

      if (!hasValue) {
        this.isRefresh = true;
        return;
      }

      this.isRefresh = false;
    });
  }

  public getFilters(): void {
    searchItems.forEach((item: SearchHelpItem, index: number) => {
      this.filters.push(new SearchTagItem(index, item));
    });
    this.tagForm = this._formBuilder.group({
      option: [0],
    });
  }

  public searchF(event: any): void {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      this.onAddTag();
    }
  }

  public setValue(value: string): void {
    this._tagInput.nativeElement.value = value;
    this.editing = true;
    this._tagInput.nativeElement.focus();
  }

  public setOption(optionName): void {
    const filter = this.filters.find((res) => {
      return res.display === optionName;
    });
    this.tagForm.setValue({ option: filter.id });
  }

  public setField(tag: TagMerged): void {
    // this.items[0] is flag list == this.flags
    // this.items[1] is status list
    this.field =
      tag.searchItem.searchItem === 'flags'
        ? this.flags.find((flag) => flag.id === tag.id)
        : this.status.find((status) => status.id === tag.id);

    this.searchForm.controls['search'].setValue(this.field.id);
    setTimeout(() => {
      // TODO: talvez exista um bug de temporização associada a seleção de edição de tags, em alguns casos este timeot não resolve o problema (ao mudar a edição de uma tag input para select)
      this.selectValue = this.field;
    });
  }

  public checkSelect(): void {
    const filterDisplay = this.filters[this.tagForm.value['option']].display;
    this.useSelect = filterDisplay === 'flags' || filterDisplay === 'status';
    this.field = null;
    this.callback();
  }

  public refreshList(): void {
    this.onAddTag();
    this.refresh.emit();
  }

  public callback() {}

  public get getItemArray(): any[] {
    this.filterDisplay = this.filters[this.tagForm.value['option']].display as
      | 'status'
      | 'flags';
    this.index = this.filterDisplay === 'status' ? 1 : 0;
    this.items[this.index].forEach((element) => {
      element.name = this._translate.getWord(element.name, 'labels', 0);
    });
    return this.items[this.index];
  }
}
